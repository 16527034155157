import axios from './axios-instance'

export default {
  getAll (payload) {
    return axios.get('v1/admin/users', { params: payload })
  },
  get (id) {
    return axios.get(`v1/admin/users/${id}`)
  },
  getSpreadSheetWithAll (search = '', isActive = 'all') {
    return axios.get('v1/admin/users', {
      params: { q: search, active: isActive },
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    })
  }
}
