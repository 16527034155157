<template>
    <v-card class="mb-6">
      <v-card-title>
        <span class="secondary--text subtitle-2">{{ title }}</span>
      </v-card-title>
      <v-divider/>
      <v-data-table
        no-data-text="Nenhum exercício cadastrado"
        disable-pagination
        hide-default-footer
        :single-expand="false"
        :expanded.sync="expanded"
        item-key="exercise.name"
        :headers="tableHeaders"
        :items="list"
      >
        <template v-slot:body="{items, isExpanded}">
          <draggable
            v-model="list"
            tag="tbody"
            handle=".drag"
            ghost-class="ghost"
            @end="updatePosition"
          >
            <tr v-for="(item, index) in items" :key="index">
              <td :colspan="headers.length" class="pa-0 ma-0">
                <data-table-row-handler
                  :item="item"
                  :headers="headers"
                  :isExpanded="isExpanded(item)"
                  :canDrag="canDrag"
                  :readonly="readonly"
                >
                  <template v-slot:item.muscleGroups="{ item }">
                    {{ Array.isArray(item.exercise.muscle_group) ? item.exercise.muscle_group.map(muscleGroup => muscleGroup.name).join(', ') : '' }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                        class="mr-2"
                        text
                        icon
                        x-small
                        color="secondary"
                        v-if="!readonly"
                        @click="newExerciseDialog(item)"
                      >
                        <v-icon>
                          mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn
                      class="mr-2"
                      text
                      icon
                      x-small
                      color="secondary"
                      v-if="!readonly"
                      @click="destroyExerciseDialog(item)"
                    >
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                    <v-btn
                      class="mr-2"
                      text
                      icon
                      x-small
                      color="secondary"
                      v-if="!readonly"
                      @click="copyExerciseDialog(item)"
                    >
                      <v-icon>
                        mdi-content-copy
                      </v-icon>
                    </v-btn>
                    <v-btn
                      text
                      icon
                      x-small
                      color="secondary"
                      v-if="item.observacao"
                      @click="expandRow(item)"
                    >
                      <v-icon>
                        {{ expandIcon(item) }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:expanded-item v-if="item.observacao">
                    <td :colspan="headers.length" class="observations grey lighten-4 grey--text text--darken-2">
                      Observações: {{ item.observacao }}
                    </td>
                  </template>
                </data-table-row-handler>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'
import DataTableRowHandler from './DataTableRowHandler.vue'
import microcycleService from '@/services/microcycle-service'

export default {
  name: 'student-training-table-micro',
  components: {
    draggable,
    DataTableRowHandler
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    headers: {
      type: Array
    },
    items: {
      type: Array
    },
    trainingIndex: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expanded: [],
      exercises: [...this.items],
      list: this.items.map((item, index) => {
        return { ...item, order: index + 1 }
      })
    }
  },
  methods: {
    ...mapActions('base', ['setLoader']),
    ...mapActions('students', ['setTrainingIndex', 'setCreateOrUpdateExerciseDialog', 'setCreateOrUpdateExercise', 'setCreateOrUpdateExerciseIndex']),
    newExerciseDialog (exercise) {
      this.setTrainingIndex(this.trainingIndex)
      this.setCreateOrUpdateExercise(exercise)
      const exerciseIndex = this.microcycleToBeEvaluated.trainings[this.trainingIndex].exercises.findIndex(el => el.exercise.id === exercise.exercise.id)
      this.setCreateOrUpdateExerciseIndex(exerciseIndex)
      this.setCreateOrUpdateExerciseDialog(false)
      this.setCreateOrUpdateExerciseDialog(true)
    },
    destroyExerciseDialog (item) {
      this.$emit('destroyExerciseDialog', item)
    },
    copyExerciseDialog (item) {
      this.$emit('copyExerciseDialog', item, this.trainingIndex)
    },
    expandRow (item) {
      this.expanded.includes(item) ? this.expanded.splice(this.expanded.indexOf(item), 1) : this.expanded.push(item)
    },
    expandIcon (item) {
      return this.expanded.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down'
    },
    updatePosition () {
      this.list.forEach((exercise, index) => {
        let currentExerciseIndex = this.exercises.findIndex(el => el.exercise.id === exercise.exercise.id)
        if (currentExerciseIndex > -1) {
          this.exercises[currentExerciseIndex].order = index + 1
        }
      })

      this.microcycleToBeEvaluated.trainings[this.trainingIndex].exercises.forEach(exercise => {
        const currentExercise = this.exercises.find(el => el.exercise.id === exercise.exercise.id)
        if (currentExercise) {
          exercise.order = currentExercise.order
        }
      })

      this.microcycleToBeEvaluated.trainings[this.trainingIndex].exercises.sort((exercise1, exercise2) => (exercise1.order > exercise2.order) ? 1 : -1)

      this.setLoader({ show: true })
      const payload = { ...this.microcycleToBeEvaluated }
      microcycleService
        .createOrUpdate(this.$route.params.id, this.$route.params.idMacrociclo, this.$route.params.idMesociclo, payload)
        .finally(() => this.setLoader({ show: false }))
    }
  },
  computed: {
    ...mapGetters('students', ['microcycleToBeEvaluated']),
    tableHeaders () {
      return this.readonly ? this.headers.slice(1, this.headers.length) : this.headers
    },
    canDrag () {
      return this.items.length > 1
    }
  },
  watch: {
    items (newVal) {
      this.expanded = []
      this.exercises = [...newVal]
      this.list = newVal.map((item, index) => {
        return { ...item, order: index + 1 }
      })
    }
  }
}
</script>

<style scoped>
  .observations {
    padding: 8px 66px;
    word-break: break-word;
  }

  .ghost {
    opacity: 0.5;
    background: #f5f5f5;
  }
</style>
