<template>
  <v-card class="elevation-2 student-meso-cycle-card" v-if="!!microcycle">
    <v-card-title class="subtitle-1">
      <v-row class="d-flex flex-row">
        <v-col cols="auto" class="pb-0">
          <span class="font-weight-medium">{{ microcycle.name }}</span>
          <span class="ml-2 caption">({{ microcycle.start_date | formatDateToBr }} - {{ microcycle.end_date |
            formatDateToBr }})</span>
        </v-col>

        <v-spacer />

        <v-col class="d-flex justify-end pb-0" cols="1">
          <v-menu offset-y v-if="!isStudent">
            <template v-slot:activator="{ on }">
              <v-btn @click.stop="" text color="secondary" class="mx-4" v-on="on">
                <v-icon>
                  mdi-dots-horizontal
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editItem(microcycle, index)">
                <v-list-item-title>
                  Editar
                </v-list-item-title>
              </v-list-item>

              <!-- <v-list-item @click="selectItemToRemove(microcycle)">
                <v-list-item-title>
                  Remover
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="selectItemCopy(microcycle)">
              </v-list-item> -->
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row class="align-center">
        <v-col class="d-flex flex-row" :cols="cols[0]" :md="md[0]" :lg="lg[0]">
          <v-text-field v-model="microcycle.goal" label="Objetivo do Microciclo" disabled readonly dense hide-details
            filled />
        </v-col>

        <v-col :cols="cols[1]" :md="md[1]" :lg="lg[1]">
          <v-text-field :value="microcycle.start_date | formatDateToBr" disabled label="Início" filled dense hide-details
            readonly />
        </v-col>

        <v-col :cols="cols[2]" :md="md[2]" :lg="lg[2]">
          <v-text-field :value="microcycle.end_date | formatDateToBr" disabled label="Fim" filled dense hide-details
            readonly />
        </v-col>
        <v-spacer />
        <v-col :cols="cols[3]" :md="md[3]" :lg="lg[3]">
          <v-row justify="end" align="center">
            <!-- <v-btn :color="microcycle.was_evaluated ? 'secondary' : 'white'" class="elevation-5 mr-3"
              @click="openEvaluationDialogMicro(microcycle)">
              <v-icon left>mdi-file-edit-outline</v-icon>
              Avaliação
            </v-btn> -->

            <v-btn :color="(microcycle.has_training || microcycle.trainings.length > 0) ? 'secondary' : 'white'" :disabled="!microcycle.was_evaluated"
              class="elevation-5 mx-3" @click="openTrainingDialog(microcycle)">
              <v-icon left> mdi-weight-lifter</v-icon>
              Treino
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <confirm-dialog title="Atenção" message="Deseja remover o microciclo selecionado? Essa ação não poderá ser desfeita"
      cancelButton="Cancelar" confirmButton="Remover" :confirmationDialog="confirmationDialog" @confirm="destroy()"
      @cancel="closeConfirmationDialog()" />

    <create-or-update-micro-dialog :dialog.sync="createOrUpdateMicroDialog" :editedItem="editedItem"
      :editedIndex="editedIndex" :macrocycleId="mesocycle.macrocycle_id" :mesocycleId="mesocycle.id" @update="updateItem(payload, index)" />

  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CreateOrUpdateMicroDialog from '@/components/student/show/CreateOrUpdateMicroDialog.vue'
import confirmDialog from '@/components/base/ConfirmDialog'
import microcycleService from '@/services/microcycle-service'

export default {
  name: 'student-micro-cycle-card',
  props: {
    microcycle: {},
    index: {},
    mesocycle: {},
    cols: {
      type: Array,
      default: function () {
        return [null, null, null, null]
      }
    },
    md: {
      type: Array,
      default: function () {
        return [null, null, null, null]
      }
    },
    lg: {
      type: Array,
      default: function () {
        return [null, null, null, null]
      }
    }
  },
  components: {
    CreateOrUpdateMicroDialog,
    confirmDialog
  },
  data: () => ({
    createOrUpdateMicroDialog: false,
    confirmationDialog: false,
    selectedItemToRemove: {},
    editedItem: {},
    editedIndex: -1
  }),
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('students', ['setMicrocycles', 'setMicrocycleToBeEvaluated', 'setMicrocycleEvaluation', 'setMicrocycleTraining', 'setStudentEvaluationDialogMicro', 'setTrainingDialogMicro']),
    openEvaluationDialogMicro (microcycle) {
      microcycleService
        .getMicrocycleEvaluation(this.student.id, this.mesocycle.macrocycle.id, microcycle.mesocycle.id, microcycle.id)
        .then(({ data }) => {
          this.setMicrocycleToBeEvaluated(microcycle)
          this.setMicrocycleEvaluation(data)
          this.setStudentEvaluationDialogMicro(true)
          // this.setStudentTrainingDialogMicro(true)
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
    },
    selectItemToCopy (mesocycle, index) {
      microcycleService.selectItemToCopy(mesocycle, index)
    },
    openTrainingDialog (microcycle) {
      microcycleService
        .getMicrocycleTraining(this.student.id, this.mesocycle.macrocycle.id, (microcycle.mesocycle_id ?? microcycle.mesocycle.id), microcycle.id)
        .then(({ data }) => {
          this.setMicrocycleToBeEvaluated(microcycle)
          this.setMicrocycleTraining(data)
          this.setTrainingDialogMicro(true)
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
    },
    editItem (item, index) {
      this.editedIndex = index
      if (index === -1) {
        const startDate = new Date().toISOString().substring(0, 10)

        this.editedItem = Object.assign({}, { ...item, name: `Microciclo ${this.mesocycle.microcycles.length + 1}`, start_date: `${startDate}` })
      } else {
        this.editedItem = Object.assign({}, item)
      }

      this.createOrUpdateMicroDialog = true
    },
    selectItemToRemove (microcycle) {
      this.selectedItemToRemove = microcycle
      this.confirmationDialog = true
    },
    selectItemCopy (microcycle) {
      microcycleService.selectItemToCopy(this.student.id, microcycle.id)
    },
    destroy () {
      this.setLoader({ show: true })
      microcycleService
        .destroy(this.student.id, this.mesocycle.macrocycle.id, this.mesocycle.id, this.selectedItemToRemove.id)
        .then(async () => {
          const responseData = await microcycleService.all(this.student.id, this.mesocycle.id)
          this.setMicrocycles({ macrocycleId: this.mesocycle.macrocycle.id, mesocycleId: this.mesocycle.id, microcycles: responseData.microcycles })
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Microciclo removido com sucesso'
          })
        })
        .catch(() => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Erro ao remover Microciclo'
          })
        })
        .finally(() => {
          this.closeConfirmationDialog()
        })
    },
    closeConfirmationDialog () {
      this.selectedItemToRemove = {}
      this.confirmationDialog = false
      this.$emit('close')
      this.setLoader({ show: false })
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'microcycleToBeEvaluated']),
    ...mapGetters('base', ['snackbar']),
    ...mapGetters('auth', ['user']),
    isStudent () {
      return this.user.isStudent()
    }
  },
  watch: {
    createOrUpdateMicroDialog () {
      this.$emit('close')
    }
  }
}
</script>
