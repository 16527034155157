<template>
  <v-card class="elevation-2 student-meso-cycle-card" v-if="!!mesocycle">
    <v-card-title class="subtitle-1">
      <v-row class="d-flex flex-row">
        <v-col cols="auto" class="pb-0">
          <span class="font-weight-medium">{{ mesocycle.name }}</span>
          <span class="ml-2 caption">({{ mesocycle.start_date | formatDateToBr }} - {{ mesocycle.end_date |
            formatDateToBr }})</span>
        </v-col>

        <v-spacer />

        <v-col class="d-flex justify-end pb-0" cols="1">
          <v-menu offset-y v-if="!isStudent">
            <template v-slot:activator="{ on }">
              <v-btn @click.stop="" text color="secondary" class="mx-4" v-on="on">
                <v-icon>
                  mdi-dots-horizontal
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editItem(mesocycle, index)">
                <v-list-item-title>
                  Editar
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="selectItemToRemove(mesocycle)">
                <v-list-item-title>
                  Remover
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="selectItemCopy(mesocycle)">
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

    </v-card-title>

    <v-card-text>
      <v-row class="align-center">
        <v-col class="d-flex flex-row" :cols="cols[0]" :md="md[0]" :lg="lg[0]">
          <v-text-field v-model="mesocycle.goal" label="Objetivo do Mesociclo" disabled readonly dense hide-details
            filled />
        </v-col>

        <v-col :cols="cols[1]" :md="md[1]" :lg="lg[1]">
          <v-text-field :value="mesocycle.start_date | formatDateToBr" disabled label="Início" filled dense hide-details
            readonly />
        </v-col>

        <v-col :cols="cols[2]" :md="md[2]" :lg="lg[2]">
          <v-text-field :value="mesocycle.end_date | formatDateToBr" disabled label="Fim" filled dense hide-details
            readonly />
        </v-col>
        <v-spacer />
        <v-col :cols="cols[3]" :md="md[3]" :lg="lg[3]">
          <v-row justify="end" align="center">
            <v-btn :color="mesocycle.was_evaluated ? 'secondary' : 'white'" class="elevation-5 mr-3"
              @click="openEvaluationDialog(mesocycle)">
              <v-icon left>mdi-file-edit-outline</v-icon>Avaliação
            </v-btn>

            <v-btn v-if="mesocycle.weeks >= 1" :color="mesocycle.weeks >= 1 ? 'secondary' : 'white'" :disabled="mesocycle.weeks == 0"
              class="elecation-5 mx-3" @click="showMicros(mesocycle)"
            >
              <v-icon left>mdi-file-table-box-multiple</v-icon>
              Microciclos
            </v-btn>
            <v-btn v-else :disabled="!mesocycle.was_evaluated" :color="mesocycle.has_training ? 'secondary' : 'white'"
              class="elevation-5 mx-3" @click="openTrainingDialog(mesocycle)">
              <v-icon left> mdi-weight-lifter</v-icon>

              Treino
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <confirm-dialog title="Atenção" message="Deseja remover o mesociclo selecionado? Isso irá apagar qualquer registro de treinos já realizados. Essa ação não poderá ser desfeita."
      cancelButton="Cancelar" confirmButton="Remover" :confirmationDialog="confirmationDialog" @confirm="destroy()"
      @cancel="closeConfirmationDialog()" />

    <create-or-update-meso-dialog :dialog.sync="createOrUpdateMesoDialog" :editedItem="editedItem"
      :editedIndex="editedIndex" :macrocycleId="macrocycle.id" @update="updateItem(payload, index)" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CreateOrUpdateMesoDialog from '@/components/student/show/CreateOrUpdateMesoDialog'
import confirmDialog from '@/components/base/ConfirmDialog'
import mesocycleService from '@/services/mesocycle-service'
// import microcycleService from '../../../services/microcycle-service'

export default {
  name: 'student-meso-cycle-card',
  props: {
    mesocycle: {},
    index: {},
    macrocycle: {},
    cols: {
      type: Array,
      default: function () {
        return [null, null, null, null]
      }
    },
    md: {
      type: Array,
      default: function () {
        return [null, null, null, null]
      }
    },
    lg: {
      type: Array,
      default: function () {
        return [null, null, null, null]
      }
    }
  },
  components: {
    CreateOrUpdateMesoDialog,
    confirmDialog
  },
  data: () => ({
    createOrUpdateMesoDialog: false,
    confirmationDialog: false,
    selectedItemToRemove: {},
    editedItem: {},
    editedIndex: -1,
    showMicrocycles: false
  }),
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('students', ['setShowMicrocycles', 'setMicrocyclesToShow', 'setMesocycles', 'setMesocycleToBeEvaluated', 'setMesocycleEvaluation', 'setMesocycleTraining', 'setStudentEvaluationDialog', 'setTrainingDialog']),
    openEvaluationDialog (mesocycle) {
      mesocycleService
        .getMesocycleEvaluation(this.student.id, mesocycle.macrocycle.id, mesocycle.id)
        .then(({ data }) => {
          this.setMesocycleToBeEvaluated(mesocycle)
          this.setMesocycleEvaluation(data)
          this.setStudentEvaluationDialog(true)
          // this.setStudentTrainingDialog(true)
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
    },
    selectItemToCopy (macrocycle, index) {
      mesocycleService.selectItemToCopy(macrocycle, index)
    },
    openTrainingDialog (mesocycle) {
      mesocycleService
        .getMesocycleTraining(this.student.id, mesocycle.macrocycle.id, mesocycle.id)
        .then(({ data }) => {
          this.setMesocycleToBeEvaluated(mesocycle)
          this.setMesocycleTraining(data)
          this.setTrainingDialog(true)
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
    },
    editItem (item, index) {
      this.editedIndex = index
      if (index === -1) {
        const startDate = new Date().toISOString().substr(0, 10)

        this.editedItem = Object.assign({}, { ...item, name: `Mesociclo ${this.macrocycle.mesocycles.length + 1}`, start_date: `${startDate}` })
      } else {
        this.editedItem = Object.assign({}, item)
      }

      this.createOrUpdateMesoDialog = true
    },
    selectItemToRemove (mesocycle) {
      this.selectedItemToRemove = mesocycle
      this.confirmationDialog = true
    },
    selectItemCopy (mesocycle) {
      mesocycleService.selectItemToCopy(this.student.id, mesocycle.id)
    },
    destroy () {
      this.setLoader({ show: true })
      mesocycleService
        .destroy(this.student.id, this.macrocycle.id, this.selectedItemToRemove.id)
        .then(async () => {
          const responseData = await mesocycleService.all(this.student.id, this.macrocycle.id)
          this.setMesocycles({ macrocycleId: this.macrocycle.id, mesocycles: responseData.mesocycles })
          this.$emit('showMicrocycles', [])
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Mesociclo removido com sucesso'
          })
        })
        .catch(() => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Erro ao remover Mesociclo'
          })
        })
        .finally(() => {
          this.closeConfirmationDialog()
        })
    },
    closeConfirmationDialog () {
      this.selectedItemToRemove = {}
      this.confirmationDialog = false
      this.$emit('close')
      this.setLoader({ show: false })
    },
    showMicros (mesocycle) {
      // microcycleService.all(this.student.id, mesocycle.macrocycle.id, mesocycle.id)
      //   .then((data) => {
      // this.setMicrocyclesToShow(data)
      // console.log(data)
      // this.setShowMicrocycles(true)
      this.$router.push({
        name: 'StudentShowMesociclo',
        params: { id: this.student.id, idMacrociclo: mesocycle.macrocycle.id, idMesociclo: mesocycle.id }
      })
      // this.$emit('showMicrocycles', data)
      // })
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'mesocycleToBeEvaluated']),
    ...mapGetters('base', ['snackbar']),
    ...mapGetters('auth', ['user']),
    isStudent () {
      return this.user.isStudent()
    }
  },
  watch: {
    createOrUpdateMesoDialog () {
      this.$emit('close')
    }
  }
}
</script>

<style></style>
