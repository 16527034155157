import axios from './axios-instance'

export default {
  getAll (payload) {
    return axios.get('v1/students', { params: payload })
  },
  get (id) {
    return axios.get(`v1/students/${id}`)
  },
  getSpreadSheetWithAll (search = '', isActive = 'all') {
    return axios.get('v1/students/export', {
      params: { q: search, active: isActive },
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    })
  },
  createOrUpdate (payload, id) {
    if (id === null) {
      return axios.post('v1/students', payload)
    }
    return axios.put(`v1/students/${id}`, payload)
  },
  studentChangeNotification (id) {
    return axios.post(`v1/students/${id}/personal_notified`)
  },
  destroy (id) {
    return axios.delete(`v1/students/${id}`)
  }
}
