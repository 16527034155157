<template>
  <div>
    <v-timeline
      :class="{'no-link': macrocycle.mesocycles.length <= 1} "
      dense
      v-if="macrocycle.mesocycles.length > 0"
    >
      <v-timeline-item
        v-for="(mesocycle, index) in macrocycle.mesocycles"
        :key="mesocycle.id"
        :color="statusColorMesocycle(mesocycle)"
      >
        <template v-slot:icon v-if="hasStatusIconMesocycle(mesocycle)">
          <v-icon size=18 dark>mdi-checkbox-marked-circle-outline</v-icon>
        </template>

        <student-meso-cycle-card
          :mesocycle="mesocycle"
          :index="index"
          :macrocycle="macrocycle"
          :cols="['12', '6', '6', '12']"
          :md="['6', '3', '3', '12']"
          :lg="['4', '2', '2', '4']"
          @showMicrocycles="handleShowMicrocycle"
        />
      </v-timeline-item>
    </v-timeline>
    <p
      class="elevation-3 pa-5 text-center"
      v-else>
      <span
      class="caption">
        Não possui nenhum Mesociclo cadastrado
      </span>
    </p>
      <v-row
        v-if="canCreate"
        class="d-flex justify-end"
      >
        <v-col class="shrink">
          <student-meso-cycle-btn-new-meso
            :macrocycleId="macrocycle.id"
            :macrocycle="macrocycle"
          />
        </v-col>
      </v-row>
  </div>

</template>

<script>
import parseDateFromString from '@/mixins/parse-date-from-string'
import StudentMesoCycleCard from '@/components/student/show/StudentMesoCycleCard'
import StudentMesoCycleBtnNewMeso from '@/components/student/show/StudentMesoCycleBtnNewMeso'
export default {
  name: 'student-meso-cycle',
  props: ['macrocycle', 'canCreate'],
  mixins: [parseDateFromString],
  components: {
    StudentMesoCycleCard,
    StudentMesoCycleBtnNewMeso
  },
  methods: {
    hasTraining (mesocycle) {
      try {
        return mesocycle.has_training
      } catch (error) {
        return false
      }
    },
    wasEvaluated (mesocycle) {
      try {
        return mesocycle.was_evaluated
      } catch (error) {
        return false
      }
    },
    isCurrent (mesocycle) {
      try {
        const today = new Date()
        return today > this.parseDateFromString(mesocycle.start_date) &&
               today < this.parseDateFromString(mesocycle.end_date)
      } catch (error) {
        return false
      }
    },
    statusColorMesocycle (mesocycle) {
      if (this.isCurrent(mesocycle)) {
        return 'primary'
      } else if (this.wasEvaluated(mesocycle) && this.hasTraining(mesocycle)) {
        return 'secondary'
      } else if (this.wasEvaluated(mesocycle) || this.hasTraining(mesocycle)) {
        return 'grey'
      } else {
        return 'grey'
      }
    },
    hasStatusIconMesocycle (mesocycle) {
      return this.wasEvaluated(mesocycle) || this.hasTraining(mesocycle)
    },
    handleShowMicrocycle (microcycle) {
      this.$emit('showMicrocycles', microcycle)
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-timeline {
    &:before {
      border: white dashed 1px;
    }
  }
  .v-timeline{
    &.no-link{
      &:before{
        display: none;
      }
    }
  }
</style>
