<template>
  <div>
    <!-- <v-expansion-panels v-model="expansionPanelState">
      <v-expansion-panel
        :key="microcycles[0].mesocycle.id"
        class="mt-2"
      > -->
        <!-- <v-expansion-panel-header class="py-0">
          <v-row>
            <v-col cols="5" class="d-flex align-center">
              <v-icon color="secondary">
                mdi-file-table-box-multiple
              </v-icon>
              <span class="ml-3 font-weight-medium secondary--text">
                Microciclos - {{ microcycles[0].mesocycle.name }}
              </span> -->
              <!-- <span
                class="caption ml-3 font-weight-regular"
                v-if="
                  microcycles.length > 0 &&
                    microcycles.start_date != null &&
                    microcycles.end_date != null
                "
              >
                <strong>Duração Aproximada: </strong>
                {{ microcycles.start_date | formatDateToBr }} -
                {{ microcycles.end_date | formatDateToBr }}
              </span>
              <span v-else class="caption ml-3 font-weight-regular">
                <strong>Duração Aproximada: </strong> Sem datas
              </span> -->
            <!-- </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex shrink justify-end"> -->
              <!-- <v-btn
                v-if="hasEnoughMesocycles(microcycles)"
                @click.stop="setMesocycles(microcycles)"
                text
                class="elevation-3 mr-4"
                color="secondary"
              >
                Comparar Avaliações
              </v-btn>
              <v-btn
                :disabled="!hasEnoughMesocycles(microcycles)"
                @click.stop="openFinalResultDialog(microcycles)"
                text
                class="elevation-3"
                color="secondary"
              >
                <v-icon :left="$vuetify.breakpoint.lgAndUp"
                  >mdi-chart-timeline-variant</v-icon
                >
                <span v-show="$vuetify.breakpoint.lgAndUp"
                  >Resultados do Macro</span
                >
              </v-btn> -->
              <!-- <v-menu offset-y v-if="!isStudent">
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click.stop=""
                    text
                    color="secondary"
                    class="mx-4"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-dots-horizontal
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editItem(microcycles, index)">
                    <v-list-item-title>
                      Editar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="selectItemToRemove(microcycles, index)">
                    <v-list-item-title>
                      Remover
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row> -->
        <!-- </v-expansion-panel-header>
        <v-expansion-panel-content> -->
          <v-timeline
            :class="{ 'no-link': microcycles.length <= 1 }"
            dense
            v-if="microcycles.length > 0"
          >
            <v-timeline-item
              v-for="(microcycle, index) in microcycles"
              :key="microcycle.id"
              :color="statusColorMicrocycle(microcycle)"
            >
              <template v-slot:icon v-if="hasStatusIconMicrocycle(microcycle)">
                <v-icon size="18" dark
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
              </template>

              <student-micro-cycle-card
                :microcycle="microcycle"
                :index="index"
                :mesocycle="mesocycleSelected"
                :cols="['12', '6', '6', '12']"
                :md="['6', '3', '3', '12']"
                :lg="['4', '2', '2', '4']"
              />
            </v-timeline-item>
          </v-timeline>
          <p class="elevation-3 pa-5 text-center mt-5" v-else>
            <span class="caption">
              Não possui nenhum Microciclo cadastrado
            </span>
          </p>
          <!-- <v-row v-if="canCreate" class="d-flex justify-end">
            <v-col class="shrink">
              <student-micro-cycle-btn-new-micro
                :mesocycleId="microcycles[0].mesocycle.id"
                :mesocycle="microcycles[0].mesocycle"
              />
            </v-col>
          </v-row> -->
        <!-- </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import parseDateFromString from '@/mixins/parse-date-from-string'
import StudentMicroCycleCard from '@/components/student/show/StudentMicroCycleCard'
// import StudentMicroCycleBtnNewMicro from '@/components/student/show/StudentMicroCycleBtnNewMicro'
export default {
  name: 'student-micro-cycle',
  props: ['microcycles', 'canCreate', 'mesocycleSelected'],
  mixins: [parseDateFromString],
  components: {
    StudentMicroCycleCard
    // StudentMicroCycleBtnNewMicro
  },
  data () {
    return {
      expansionPanelState: 0
    }
  },
  methods: {
    hasTraining (microcycle) {
      try {
        microcycle.has_training = microcycle?.has_training ?? microcycle?.trainings?.length > 0

        return microcycle.has_training
      } catch (error) {
        return false
      }
    },
    wasEvaluated (microcycle) {
      try {
        return microcycle.was_evaluated
      } catch (error) {
        return false
      }
    },
    isCurrent (microcycle) {
      try {
        const today = new Date()
        return (
          today > this.parseDateFromString(microcycle.start_date) &&
          today < this.parseDateFromString(microcycle.end_date)
        )
      } catch (error) {
        return false
      }
    },
    statusColorMicrocycle (microcycle) {
      if (this.isCurrent(microcycle)) {
        return 'primary'
      } else if (
        this.wasEvaluated(microcycle) &&
        this.hasTraining(microcycle)
      ) {
        return 'secondary'
      } else if (
        this.wasEvaluated(microcycle) ||
        this.hasTraining(microcycle)
      ) {
        return 'grey'
      } else {
        return 'grey'
      }
    },
    hasStatusIconMicrocycle (microcycle) {
      return this.wasEvaluated(microcycle) || this.hasTraining(microcycle)
    }
  },
  computed: {
    ...mapGetters('students', ['student'])
  },
  watch: {
    microcycles: (newVal) => {
      console.log(newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-timeline {
  &:before {
    border: white dashed 1px;
  }
}
.v-timeline {
  &.no-link {
    &:before {
      display: none;
    }
  }
}
</style>
