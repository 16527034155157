<template>
  <v-card min-width="240" max-width="280px" min-height="400" class="elevation-6">
    <v-card-title>
      <span class="subtitle-1 font-weight-bold">{{ title }}</span>
      <v-btn icon small top right absolute>
        <v-icon color="secondary">mdi-information</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pa-0">
      <v-img ref="previewImage" class="secondary lighten-3" :src="preview" max-height="288px" min-height="288px"
        min-width="240px" max-width="280px">
        <v-container fill-height fluid v-if="!preview">
          <v-row justify="center" class="ma-0">
            <v-col cols="12" align="center">
              <v-icon size="111" color="secondary">mdi-image-plus</v-icon>
            </v-col>
            <v-col cols="12" align="center">
              <span>Clique ou arraste para selecionar uma imagem!</span>
            </v-col>
          </v-row>
        </v-container>
        <input ref="inputImage" :style="styleInput" @change="getImage" type="file" accept="image/*">
        <v-btn icon v-if="hasUploadedImage()" class="elevation-4 white" top right absolute @click="destroy">
          <v-icon color="secondary">mdi-delete</v-icon>
        </v-btn>
      </v-img>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-btn color="secondary" :disabled="!hasUploadedImage() || !canUpload" @click="upload">
        <v-icon left>mdi-image-plus</v-icon> salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import mesocycleService from '@/services/mesocycle-service'
import { isEmpty } from 'lodash'
export default {
  name: 'preview-upload-photo-card',
  props: ['title', 'objectKey', 'editImage'],
  data: () => ({
    preview: '',
    image: null,
    canUpload: false
  }),
  computed: {
    ...mapGetters('students', ['student', 'mesocycleToBeEvaluated']),
    styleInput () {
      return {
        position: 'absolute',
        opacity: '0',
        top: '0px',
        left: '0px',
        width: '240px',
        height: '288px'
      }
    }
  },
  methods: {
    ...mapActions('base', ['setSnackbar', 'setLoader']),
    ...mapActions('students', ['updateMesocycleToBeEvaluated']),
    getImage ({ target }) {
      if (!isEmpty(target.files)) {
        if (target.files[0].size <= 5242880) { // menor ou igual que 5MB
          this.image = target.files[0]
          this.preview = URL.createObjectURL(this.image) // set preview
          this.canUpload = true
        } else {
          this.$refs.inputImage.value = ''
          this.setSnackbar({
            show: true,
            message: 'A imagem tem um limite de 5 MB!',
            color: 'error'
          })
        }
      }
    },
    destroy () {
      this.setLoader({ show: true })
      if (this.image != null || this.preview !== '') {
        const formData = new FormData()
        formData.append(this.objectKey, [])
        formData.append('id', this.mesocycleToBeEvaluated.id)

        mesocycleService
          .uploadPhoto(this.student.id, this.mesocycleToBeEvaluated.macrocycle.id, this.mesocycleToBeEvaluated.id, formData)
          .then(({ data }) => {
            this.setSnackbar({
              color: 'success',
              show: true,
              message: 'Imagem removida com sucesso'
            })
            this.image = null
            this.$refs.inputImage.value = ''
            URL.revokeObjectURL(this.$refs.previewImage.src)
            this.preview = ''
            this.updateMesocycleToBeEvaluated(data.mesocycle)
          }).catch(({ response }) => {
            this.setSnackbar({
              color: 'error',
              show: true,
              message: response.data.errors.detail
            })
          }).finally(() => {
            this.setLoader({ show: false })
          })
      } else {
        this.setSnackbar({
          show: true,
          message: 'Nenhuma imagem para apagar',
          color: 'error'
        })
        this.setLoader({ show: false })
      }
    },
    upload () {
      this.setLoader({ show: true })
      if (this.image != null) {
        const formData = new FormData()
        formData.append(this.objectKey, this.image)
        formData.append('id', this.mesocycleToBeEvaluated.id)

        mesocycleService
          .uploadPhoto(this.student.id, this.mesocycleToBeEvaluated.macrocycle.id, this.mesocycleToBeEvaluated.id, formData)
          .then(({ data }) => {
            this.setSnackbar({
              color: 'success',
              show: true,
              message: 'Imagem salva com sucesso'
            })
            this.updateMesocycleToBeEvaluated(data.mesocycle)
            this.canUpload = false
          }).catch(({ response }) => {
            this.setSnackbar({
              color: 'error',
              show: true,
              message: response.data.errors.detail || 'Error ao atualizar'
            })
          }).finally(() => {
            this.setLoader({ show: false })
          })
      } else {
        this.setSnackbar({
          show: true,
          message: 'Nenhuma imagem foi selecionada',
          color: 'error'
        })
        this.setLoader({ show: false })
      }
    },
    hasUploadedImage () {
      return !!this.preview
    }
  },
  watch: {
    editImage (newVal) {
      if (!isEmpty(newVal)) {
        this.preview = newVal // set preview
        this.canUpload = false
      }
    }
  },
  mounted () {
    if (!isEmpty(this.editImage)) {
      this.preview = this.editImage
      this.canUpload = false
    }
    this.$refs.inputImage.onload = function () {
      URL.revokeObjectURL(this.$refs.previewImage.src)
    }
  }
}
</script>

<style></style>
