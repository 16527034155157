<template>
  <div>
    <confirm-dialog title="Atenção"
      message="Deseja remover a Periodização selecionada? Essa ação não poderá ser desfeita" cancelButton="Cancelar"
      confirmButton="Remover" :confirmationDialog="confirmationDialog" @confirm="destroy()"
      @cancel="closeConfirmationDialog()" />

    <v-expansion-panels v-if="mode !== 3" v-model="expansionPanelState">
      <v-expansion-panel v-for="(macrocycle, index) in macrocycles" :key="macrocycle.id" class="mt-2">
        <v-expansion-panel-header class="py-0">
          <v-row>
            <v-col cols="5" class="d-flex align-center">
              <v-icon color="secondary">
                mdi-file-table-box-multiple
              </v-icon>
              <span class="ml-3 font-weight-medium secondary--text">
                {{ macrocycle.name }}
              </span>
              <span class="caption ml-3 font-weight-regular"
                v-if="macrocycle.mesocycles.length > 0 && macrocycle.start_date != null && macrocycle.end_date != null">
                <strong>Duração Aproximada: </strong> {{ macrocycle.start_date | formatDateToBr }} - {{
                  macrocycle.end_date
                  | formatDateToBr }}
              </span>
              <span v-else class="caption ml-3 font-weight-regular">
                <strong>Duração Aproximada: </strong> Sem datas
              </span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex shrink justify-end">
              <v-btn v-if="hasEnoughMesocycles(macrocycle)" @click.stop="setMesocycles(macrocycle)" text
                class="elevation-3 mr-4" color="secondary">
                Comparar Avaliações
              </v-btn>
              <v-btn :disabled="!hasEnoughMesocycles(macrocycle)" @click.stop="openFinalResultDialog(macrocycle)" text
                class="elevation-3" color="secondary">
                <v-icon :left="$vuetify.breakpoint.lgAndUp">mdi-chart-timeline-variant</v-icon>
                <span v-show="$vuetify.breakpoint.lgAndUp">Resultados do Macro</span>
              </v-btn>
              <v-menu offset-y v-if="!isStudent">
                <template v-slot:activator="{ on }">
                  <v-btn @click.stop="" text color="secondary" class="mx-4" v-on="on">
                    <v-icon>
                      mdi-dots-horizontal
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editItem(macrocycle, index)">
                    <v-list-item-title>
                      Editar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="selectItemToRemove(macrocycle, index)">
                    <v-list-item-title>
                      Remover
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <student-meso-cycle v-if="mode === 0" :key="'mesocycle-' + mode" :macrocycle="macrocycle" :canCreate="!isStudent" @showMicrocycles="handleShowMicrocycles"/>
          <student-micro-cycle v-if="mode === 3" :key="'microcycle-' + mode" :microcycles="microcyclesToShow" :canCreate="!isStudent" />
          <student-macro-calendar v-else-if="mode === 1" :key="'macrocycle' + mode" :macrocycle="macrocycle" :canCreate="!isStudent" />
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>

    <v-expansion-panels v-else v-model="expansionPanelState">
      <v-expansion-panel :key="mesocycleSelected.id" class="mt-2">
        <v-expansion-panel-header class="py-0">
          <v-row>
            <v-col cols="5" class="d-flex align-center">
              <v-icon color="secondary">
                mdi-file-table-box-multiple
              </v-icon>
              <span class="ml-3 font-weight-medium secondary--text">
                {{ mesocycleSelected.name }}
              </span>
              <span class="caption ml-3 font-weight-regular"
                v-if="mesocycleSelected.microcycles.length > 0 && mesocycleSelected.start_date != null && mesocycleSelected.end_date != null">
                <strong>Duração Aproximada: </strong> {{ mesocycleSelected.start_date | formatDateToBr }} - {{
                  mesocycleSelected.end_date
                  | formatDateToBr }}
              </span>
              <span v-else class="caption ml-3 font-weight-regular">
                <strong>Duração Aproximada: </strong> Sem datas
              </span>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <student-micro-cycle v-if="mode === 3" :key="'microcycle-' + mode" :mesocycleSelected="mesocycleSelected" :microcycles="mesocycleSelected.microcycles" :canCreate="!isStudent" />
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>

    <compare-evaluations-dialog v-if="selectedMesocycles.length > 0" :mesocycles="selectedMesocycles"
      @closeCompareDialog="closeCompareDialog" />
  </div>

</template>

<script>
import StudentMesoCycle from '@/components/student/show/StudentMesoCycle'
import StudentMicroCycle from '@/components/student/show/StudentMicroCycle'
import { mapGetters, mapActions } from 'vuex'
import macrocycleService from '@/services/macrocycle-service'
import confirmDialog from '@/components/base/ConfirmDialog'
import StudentMacroCalendar from '@/components/student/show/StudentMacroCalendar'
import CompareEvaluationsDialog from '@/components/student/show/CompareEvaluationsDialog'

export default {
  name: 'student-macro-cycle',
  components: {
    StudentMesoCycle,
    StudentMicroCycle,
    StudentMacroCalendar,
    confirmDialog,
    CompareEvaluationsDialog
  },
  mounted () {
    console.log(this.macrocycles)
  },
  props: ['mode'], // if calendar or list mode
  data () {
    return {
      dialog: false,
      editedItem: {},
      editedIndex: -1,
      expansionPanelState: 0,
      selectedItemToRemove: {},
      selectedItemToRemoveIndex: -1,
      selectedMesocycles: [],
      // selectedMicrocycles: [],
      confirmationDialog: false,
      showMicrocycles: false,
      microcyclesToShow: []
    }
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('students', ['destroyMacrocycle', 'setFinalResultDialog', 'setCurrentMacroCycle']),
    editItem (item, index) {
      this.$emit('openCreateOrUpdateMacrocycle', {
        editedItem: Object.assign({}, item),
        editedIndex: index
      })
    },
    selectItemToRemove (macrocycle, index) {
      this.selectedItemToRemove = macrocycle
      this.selectedItemToRemoveIndex = index
      this.confirmationDialog = true
    },

    destroy () {
      this.setLoader({ show: true })
      macrocycleService
        .destroy(this.student.id, this.selectedItemToRemove.id)
        .then(() => {
          this.destroyMacrocycle(this.selectedItemToRemoveIndex)
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Periodização removida com sucesso'
          })
        })
        .catch(() => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Erro ao remover Periodização'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
          this.closeConfirmationDialog()
        })
    },
    closeConfirmationDialog () {
      this.confirmationDialog = false
      this.selectedItemToRemove = {}
      this.selectedItemToRemoveIndex = -1
    },
    openFinalResultDialog (macrocycle) {
      this.setCurrentMacroCycle(macrocycle.id)
      this.setFinalResultDialog(true)
    },
    hasEnoughMesocycles (macrocycle) {
      try {
        var mesocyclesEvaluatedQuantity = 0
        macrocycle.mesocycles.forEach(mesocycle => {
          if (mesocycle.was_evaluated) {
            mesocyclesEvaluatedQuantity++
          }
        })
        return mesocyclesEvaluatedQuantity >= 2
      } catch (e) {
        return false
      }
      // return macrocycle.evaluation_result && macrocycle.evaluation_result.length > 1
    },
    setMesocycles (macrocycle) {
      this.selectedMesocycles = macrocycle.mesocycles
      console.log(macrocycle)
    },
    // setMicrocycles (macrocycle) {
    //   this.selectedMicrocycles = macrocycle.microcycles
    // },
    closeCompareDialog () {
      this.selectedMesocycles = []
      // this.selectedMicrocycles = []
    },
    handleShowMicrocycles ({ microcycles }) {
      this.showMicrocycles = !this.showMicrocycles
      this.microcyclesToShow = microcycles
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'macrocycles']),
    ...mapGetters('auth', ['user']),
    isStudent () {
      return this.user.isStudent()
    },
    mesocycleSelected () {
      const macrocycle = this.macrocycles.find(macro => macro.id === this.$route.params.idMacrociclo)
      return macrocycle.mesocycles.find(meso => meso.id === this.$route.params.idMesociclo)
    }
  },
  watch: {
    dialog (newValue) {
      if (!newValue) {
        this.editedItem = {}
        this.editedIndex = -1
      }
    },
    showMicrocycles (newVal) {
      if (newVal) {
        console.log('Microcycles atualizados:', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-expansion-panel-header {
  &:after {
    content: "";
    width: 100%;
    height: 1px;
    background: #F2F2F2;
    position: absolute;
    top: 59px;
    left: 0;

  }
}

;
</style>
