import axios from './axios-instance'

export default {
  async all (studentId, macrocycleId, mesocycleId) {
    const { data } = await axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/microcycles`)
    return data
  },
  evaluationsResult (studentId, macrocycleId, mesocycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/microcycles`)
  },
  getMicrocycle (studentId, macrocycleId, mesocycleId, microcycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/microcycles/${microcycleId}`)
  },
  getMicrocycleEvaluation (studentId, macrocycleId, mesocycleId, microcycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/microcycles/${microcycleId}/evaluation`)
  },
  getMicrocycleTraining (studentId, macrocycleId, mesocycleId, microcycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/microcycles/${microcycleId}/trainings`)
  },
  createOrUpdate (studentId, macrocycleId, mesocycleId, payload) {
    if (!payload.id) {
      return axios.post(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/microcycles`, payload)
    } else {
      return axios.put(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/microcycles/${payload.id}`, payload)
    }
  },
  microcycleSaveEvaluation (studentId, macrocycleId, mesocycleId, payload) {
    return axios.put(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/microcycles/${payload.id}`, payload)
  },
  uploadPhoto (studentId, macrocycleId, mesocycleId, microcycleId, formData) {
    return axios.put(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/microcycles/${microcycleId}`, formData)
  },
  destroy (studentId, macrocycleId, mesocycleId, id) {
    return axios.delete(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/microcycles/${id}`)
  },
  removeTraining (microcycleId, trainingIdx) {
    return axios.put(`v1/microcycles/${microcycleId}/training/${trainingIdx}`)
  },
  getFinalResult (studentId, macrocycleId, mesocycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/microcycles/`)
  },
  selectItemToCopy (studentId, id, origin, cycle = 'micro') {
    if (cycle === 'meso') { return axios.post(`v1/training_copy?idStudent=${studentId}&idMicrocycles=${id}&idMesocyclesOrigin=${origin}`) }

    return axios.post(`v1/training_copy?idStudent=${studentId}&idMicrocycles=${id}&idMicrocyclesOrigin=${origin}`)
  },
  getmicrocyclesList (studentId) {
    return axios.get(`v1/microcyclesList/${studentId}`)
  }
}
