<template>
  <v-container>
    <v-row v-if="!isStudent">
      <v-col cols="12" class="px-0">
        <btn-back />
      </v-col>
    </v-row>
    <template v-if="!showPaymentHistory">
      <student-card-details :dialog.sync="dialogStudentForm" class="mt-6" @toggle="togglePaymentHistory" />
      <template>

        <v-row class="pt-2">
          <v-col v-if="student.macrocycles.length === 0" cols="12" class="pa-0">
            <div class="dashed-container pa-30 d-flex justify-center align-center">
              <img width="120" src="@/assets/images/students/show/calendar-with-clock.png" />
            </div>

          </v-col>
          <v-col class="px-0" v-else>

            <student-macro-cycle :mode="macroMode" @openCreateOrUpdateMacrocycle="editItem($event)" />

          </v-col>

        </v-row>

        <dialog-full-screen :dialog.sync="dialogStudentForm" titleName="Editar Aluno" buttonName="Salvar"
          @button="$refs.studentForm.save()">
          <student-form :dialog.sync="dialogStudentForm" ref="studentForm" @updateList="getStudent()" />
        </dialog-full-screen>

        <student-training-detail-micro v-if="studentTrainingDialogMicro" @update="updateTraining('micro', $event)" @updateStudantData="getStudent()" />
        <create-or-update-exercise-dialog @update="updateExercise($event)" />
      </template>
    </template>
  </v-container>

</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import StudentCardDetails from '@/components/student/show/StudentCardDetails'
import StudentMacroCycle from '@/components/student/show/StudentMacroCycle'
import DialogFullScreen from '@/components/base/DialogFullScreen'
import StudentForm from '@/components/student/StudentForm'
import StudentService from '@/services/student-service'
import StudentTrainingDetailMicro from '@/components/student/show/StudentTrainingDetailMicro'
import CreateOrUpdateExerciseDialog from '@/components/student/show/CreateOrUpdateExerciseDialog'
import mesocycleService from '@/services/mesocycle-service'
import microcycleService from '@/services/microcycle-service'
import BtnBack from '../../components/base/BtnBack.vue'
import StrengthTestService from '@/services/user-strength-test-service'

export default {
  name: 'student-show',
  components: {
    StudentCardDetails,
    StudentMacroCycle,
    DialogFullScreen,
    StudentForm,
    StudentTrainingDetailMicro,
    CreateOrUpdateExerciseDialog,
    BtnBack
  },
  beforeMount () {
    this.getStudent()
    this.getStrengthTest()
  },
  data () {
    return {
      // messages: [],
      // newMessage: '',
      // strengthTests: [],
      // headersStrengthTest: [
      //   { text: 'Exercício', value: 'exercise' },
      //   { text: 'Repetição max.', value: 'maxReps' },
      //   { text: 'Peso utilizado', value: 'weightUsed' },
      //   { text: 'Grupo muscular', value: 'muscleGroup' }
      // ],
      dialogStudentForm: false,
      dialog: false,
      strengthTestDialog: false,
      // chatDialog: false,
      macroMode: 3,
      editedItem: {},
      editedIndex: -1,
      showPaymentHistory: false
    }
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('students', ['setStudent', 'setCycleType']),
    getStudent () {
      this.setLoader({ show: true })
      StudentService
        .get(this.$route.params.id)
        .then(({ data }) => {
          this.setStudent(data.student)
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Erro ao carregar aluno'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    getStrengthTest () {
      const studentId = this.$route.params.id
      this.setLoader({ show: true })
      StrengthTestService
        .getStrengthTest(studentId)
        .then(({ data }) => {
          this.strengthTests = data.strength_tests || []
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Erro ao carregar teste de força'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    // sendMessage() {
    //   if (!this.newMessage.trim()) {
    //     return;
    //   }
    //   if (this.newMessage.trim() !== '') {
    //     this.messages.push({
    //       user: 'Você',
    //       text: this.newMessage
    //     });
    //     this.newMessage = '';

    //     setTimeout(() => {
    //       this.messages.push({
    //         user: 'Bot',
    //         text: 'Resposta automática...'
    //       });
    //     }, 1000);
    //   }
    //   this.$nextTick(() => {
    //     const container = this.$refs.messageContainer;
    //     if(container) {
    //       container.scrollTop = container.scrollHeight;
    //     }
    //     });
    //   this.newMessage = '';
    // },
    // addNewLine() {
    //   this.newMessage += '\n';
    // },
    newMacroCycle () {
      this.editedItem = { id: null, items_to_be_evaluated: [], skinfold_protocol: 'Guedes - 3 Dobras', vo2_protocol: 'protocol_3' }
      this.editedIndex = -1
      this.dialog = true
    },
    editItem ({ editedItem, editedIndex }) {
      this.editedItem = editedItem
      this.editedIndex = editedIndex
      this.dialog = true
    },
    editStudent () {
      this.setStudent(this.student)
      this.dialogStudentForm = true
    },
    updateTraining (mode, { successMessage, failMessage }) {
      this.update(mode)
        .then(() => {
          this.showSnackbar('success', successMessage)
        })
        .catch(() => {
          this.showSnackbar('error', failMessage)
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    updateExercise ({ successMessage, failMessage, cycle }) {
      this.update(cycle)
        .then(() => {
          this.showSnackbar('success', successMessage)
        })
        .catch(() => {
          this.showSnackbar('error', failMessage)
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    update (cycleParam = 'meso') {
      this.setCycleType(cycleParam)
      this.setLoader({ show: true })
      if (cycleParam === 'micro') {
        const payload = { ...this.microcycleToBeEvaluated }
        return microcycleService
          .createOrUpdate(this.student.id, this.$route.params.idMacrociclo, payload.mesocycle_id, payload)
      } else {
        const payload = { ...this.mesocycleToBeEvaluated }
        return mesocycleService
          .createOrUpdate(this.student.id, payload.macrocycle.id, payload)
      }
    },
    showSnackbar (color, message) {
      this.setSnackbar({
        color,
        show: true,
        message
      })
    },
    togglePaymentHistory () {
      this.showPaymentHistory = !this.showPaymentHistory
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'mesocycleToBeEvaluated', 'microcycleToBeEvaluated', 'studentEvaluationDialog', 'studentEvaluationDialogMicro', 'studentTrainingDialog', 'studentTrainingDialogMicro']),
    ...mapGetters('auth', ['user']),
    isStudent () {
      return this.user.isStudent()
    }
  }
  // updated() {
  //   const container = this.$refs.messageContainer;
  //   if(container) {
  //     container.scrollTop = container.scrollHeight;
  //   }
  // }

}
</script>

<style scoped>
.pa-30 {
  padding: 60px !important;
}

.dashed-container {
  border: dashed 2px darkgrey;
}

.macro-mode--active {
  background-color: var(--v-secondary-base) !important;
}

.macro-mode--active>.v-btn__content>i {
  color: #fff !important;
}

.macro-mode>:not(.macro-mode--active)>.v-btn__content>i {
  color: var(--v-secondary-base) !important;
}

.macro-mode>.v-btn {
  border-radius: 8px !important;
}

.v-dialog__content {
  padding: 0 60px !important;
  justify-content: flex-end !important;
}

.user {
  background-color: #eeadbb44;
}

.otherUser {
  background-color: #a0b6eb44;
}

.chatContainer {
  height: 70%;
  overflow-y: auto;
  margin-top: 20px;
}

.message {
  border-radius: 0.5rem;
  width: fit-content;
  max-width: 100%;
  padding: 0.5rem;
  min-width: 20%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.messageUser {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.messageOtherUser {
  width: 100%;
}
</style>
